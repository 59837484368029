import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";

export const ClientAnalyticsTable = ({ clientsList }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: "Client ID",
                accessor: "patient_id",
            },
            {
                Header: "Client Name",
                accessor: "pname",
            },
            {
                Header: "Rejected",
                accessor: "Rejected",
            },
            {
                Header: "Completed",
                accessor: "Completed",
            },
            {
                Header: "Pending",
                accessor: "Pending",
            },
            {
                Header: "Total",
                accessor: "Total",
            },
        ],
        []
    )

    var tableRender = (
        <Table
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: console.log("column", column)
                }
            }}
            path={"/analytics/clientAnalytic"}
            columns={columns}
            data={clientsList}
        />
    )

    return <TableStyles>{clientsList ? tableRender : " "}</TableStyles>
}