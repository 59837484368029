import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";

export const DoctorAnalyticsTable = ({ doctorsList }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: "Doctor ID",
                accessor: "doctor_id",
            },
            {
                Header: "Doctor Name",
                accessor: "lname",
            },
            {
                Header: "Booked",
                accessor: "Booked",
            },
            {
                Header: "Confirmed",
                accessor: "Confirmed",
            },
            {
                Header: "Completed",
                accessor: "Completed",
            },
            {
                Header: "Cancelled",
                accessor: "Cancelled",
            },
            {
                Header: "Ongoing",
                accessor: "Ongoing",
            },
            {
                Header: "Total",
                accessor: "Total",
            },
        ],
        []
    )

    var tableRender = (
        <Table
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    onClick: console.log("column", column)
                }
            }}
            // path={"/analytics/counsellorAnalytic"}
            path={"/analytics/doctorAnalytic"}
            columns={columns}
            data={doctorsList}
        />
    )

    return <TableStyles>{doctorsList ? tableRender : " "}</TableStyles>
}