import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { fetchListenersList, fetchClientsList, fetchDoctorsList } from "../../state/analytics/analyticsActions"
import { BallBeat } from "react-pure-loaders"
import { ListenerAnalyticsTable } from "../../components/ListenerAnalyticsTable"
import { ClientAnalyticsTable } from "../../components/ClientAnalyticsTable"
import { DoctorAnalyticsTable } from "../../components/DoctorAnalyticsTable"
import { useDispatch, useSelector } from "react-redux"

export const AnalyticsDisplayContainer = ({ selectedTab }) => {

    const dispatch = useDispatch()
    const analyticsData = useSelector((state) => state.analytics)
    const error = useSelector((state) => state.analytics.error)
    const loading = useSelector((state) => state.analytics.loading)
    const listenersList = useSelector((state) => state.analytics.listenerAnalytics)
    const clientsList = useSelector((state) => state.analytics.clientAnalytics)
    const doctorsList = useSelector((state) => state.analytics.doctorAnalytics)
    // const selectedTab = useSelector((state) => state.analytics.selectedTab)

    useEffect(() => {
        dispatch(fetchListenersList())
    }, [fetchListenersList])

    useEffect(() => {
        dispatch(fetchClientsList())
    }, [fetchClientsList])

    useEffect(() => {
        dispatch(fetchDoctorsList())
    }, [fetchDoctorsList])

    const [listeners, setListeners] = useState(true)
    const [clients, setClients] = useState(false)
    const [doctors, setDoctors] = useState(false)

    useEffect(() => {
        console.log("SELECTEDDD TABBB", selectedTab)
        if (selectedTab === "LISTENERS") {
            console.log("SELECTED TABB", selectedTab)
            setListeners(true);
            setClients(false);
            setDoctors(false);
        }
        else if (selectedTab === "CLIENTS") {
            console.log("SELECTED TABB", selectedTab)
            setListeners(false);
            setClients(true);
            setDoctors(false);
        }
        else if (selectedTab === "DOCTORS") {
            console.log("SELECTED TABB", selectedTab)
            setListeners(false);
            setClients(false);
            setDoctors(true);
        }
    }, [selectedTab])


    return (
        <>
            {listeners && <>
                {analyticsData && loading ?
                    (
                        <>
                            <br />
                            <br />
                            <h4>
                                Loading Listeners Analytics {" "}
                                <span>
                                    <BallBeat color={"#123abc"} loading={analyticsData.loading} />
                                </span>
                            </h4>
                        </>
                    ) : (error ?
                        <>
                            <br />
                            <h2> Listeners  Analytics</h2>
                            <br />
                            <h4> ERROR ⚠️ FETCHING LISTENER ANALYTICS</h4>
                            <br />
                            <p style={{ color: "grey" }}>{analyticsData.error}</p>
                            <br />
                            <br />
                        </>
                        :
                        listenersList.length === 0 ?
                            (
                                <>
                                    <br />
                                    <p>
                                        Listener  Analytics</p>
                                    <br />
                                    <h4> Listener Analytics not available</h4>
                                    <br />
                                    <br />
                                    <br />
                                </>
                            ) :
                            <>
                                <br />
                                <br />
                                <h4>
                                    Listener  Analytics
                                </h4>
                                <ListenerAnalyticsTable listenersList={listenersList} />
                            </>
                    )}
            </>}
            {clients && <>
                {analyticsData && loading ?
                    (
                        <>
                            <br />
                            <br />
                            <h4>
                                Loading Client Analytics {" "}
                                <span>
                                    <BallBeat color={"#123abc"} loading={analyticsData.loading} />
                                </span>
                            </h4>
                        </>
                    ) : (error ?
                        <>
                            <br />
                            <h2> Client  Analytics</h2>
                            <br />
                            <h4> ERROR ⚠️ FETCHING CLIENT ANALYTICS</h4>
                            <br />
                            <p style={{ color: "grey" }}>{analyticsData.error}</p>
                            <br />
                            <br />
                        </>
                        :
                        clientsList.length === 0 ?
                            (
                                <>
                                    <br />
                                    <p>
                                        Client  Analytics</p>
                                    <br />
                                    <h4> Client Analytics not available</h4>
                                    <br />
                                    <br />
                                    <br />
                                </>
                            ) :
                            <>
                                <br />
                                <br />
                                <h4>
                                    Client  Analytics
                                </h4>
                                <ClientAnalyticsTable clientsList={clientsList} />
                            </>
                    )}
            </>}
            {doctors && <>
                {analyticsData && loading ?
                    (
                        <>
                            <br />
                            <br />
                            <h4>
                                Loading Doctor Analytics {" "}
                                <span>
                                    <BallBeat color={"#123abc"} loading={analyticsData.loading} />
                                </span>
                            </h4>
                        </>
                    ) : (error ?
                        <>
                            <br />
                            <h2> Doctor  Analytics</h2>
                            <br />
                            <h4> ERROR ⚠️ FETCHING DOCTOR ANALYTICS</h4>
                            <br />
                            <p style={{ color: "grey" }}>{analyticsData.error}</p>
                            <br />
                            <br />
                        </>
                        :
                        doctorsList.length === 0 ?
                            (
                                <>
                                    <br />
                                    <p>
                                        Doctor  Analytics</p>
                                    <br />
                                    <h4> Doctor Analytics not available</h4>
                                    <br />
                                    <br />
                                    <br />
                                </>
                            ) :
                            <>
                                <br />
                                <br />
                                <h4>
                                    Doctor  Analytics
                                </h4>
                                <DoctorAnalyticsTable doctorsList={doctorsList} />
                            </>
                    )}
            </>}
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        // analyticsData: state.analytics,
        // listenersList: state.analytics.listenerAnalytics,
        // clientsList: state.analytics.clientAnalytics,
        // doctorsList: state.analytics.doctorAnalytics,
        // user: state.login.user,
        selectedTab: state.analytics.selectedTab
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchListenersList: () => dispatch(fetchListenersList()),
        fetchClientsList: () => dispatch(fetchClientsList()),
        fetchDoctorsList: () => dispatch(fetchDoctorsList()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsDisplayContainer);
